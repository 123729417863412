import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Discount } from '@commercetools/frontend-domain-types/cart/Discount';
import useSWR, { mutate } from 'swr';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { BRANDS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import useI18n from 'helpers/hooks/useI18n';
import { sdk } from 'sdk';
import { Cart } from 'types/cart';
import { Order } from 'types/order';
import { Variant } from 'types/product';
import { ENikonProductTypeKeys, TNikonProduct } from 'types/TNikonProduct';
import { revalidateOptions, useAccount, useProduct } from 'frontastic';
import { ECOMM_SITES } from './../../../helpers/constants/cartConstants';
import { CartDetails, UseCartReturn } from './types';
import { Orders } from '../../../../types/cart/Orders';
import { PaymentExtended } from '../../../../types/cart/PaymentExtended';
import { ShippingMethod } from '../../../../types/cart/ShippingMethod';
import { PaginatedQuery } from '../../../../types/query/PaginatedQuery';
import { Category } from 'types/category';

const useCart = (): UseCartReturn => {
  const extensions = sdk.composableCommerce;

  const router = useRouter();
  const { account } = useAccount();
  const { getProductByKey } = useProduct();
  const locale = router.locale;

  const { currency } = useI18n();

  const result = useSWR(
    locale && ECOMM_SITES.includes(locale) ? '/action/cart/getCart' : null,
    extensions.cart.getCart,
    revalidateOptions,
  );

  const shippingMethodsResults = useSWR(
    locale && ECOMM_SITES.includes(locale) ? '/action/cart/getShippingMethods' : null,
    extensions.cart.getShippingMethods,
    revalidateOptions,
  );

  const data = result.data?.isError ? {} : { data: result.data?.data as unknown as Cart };

  const shippingMethods = shippingMethodsResults.data?.isError ? {} : { data: shippingMethodsResults.data?.data };

  const totalItems = (data.data as Cart)?.lineItems?.reduce((acc, curr) => acc + (curr.count as number), 0) ?? 0;

  const isEmpty = !data?.data?.lineItems?.length;

  const isShippingAccurate = !!data?.data?.shippingInfo;

  const hasOutOfStockItems = !!data?.data?.lineItems?.some((lineItem) => !lineItem.variant?.isOnStock);

  const cartTotalThreshold = (data.data as Cart)?.cartTotalThreshold;

  const isApplePayEnabled = (data.data as Cart)?.applePayEnabled;

  const isGooglePayEnabled = (data.data as Cart)?.googlePayEnabled;

  const isPaypalEnabled = (data.data as Cart)?.paypalEnabled;

  const transaction = useMemo(() => {
    const cartData = data.data;

    if (!cartData?.lineItems?.length)
      return {
        subtotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        discount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        tax: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        shipping: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        total: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
        cartDiscountTotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
      };

    const currencyCode = cartData.sum?.currencyCode ?? currency;
    const fractionDigits = cartData.sum?.fractionDigits ?? 2;

    return {
      subtotal: {
        centAmount: cartData?.custom?.fields?.subtotal?.centAmount,
        currencyCode,
        fractionDigits,
      },
      discount: {
        centAmount: cartData?.custom?.fields?.productDiscountTotal?.centAmount,
        currencyCode,
        fractionDigits,
      },
      cartDiscountTotal: {
        centAmount: cartData?.custom?.fields?.cartDiscountTotal?.centAmount,
        currencyCode,
        fractionDigits,
      },
      shipping: {
        centAmount: cartData?.shippingInfo?.price?.centAmount ?? 0,
        currencyCode,
        fractionDigits,
      },
      tax: {
        centAmount: cartData?.taxed?.totalTax?.centAmount ?? 0,
        currencyCode,
        fractionDigits,
      },
      total: {
        centAmount: cartData?.taxed?.totalGross?.centAmount
          ? cartData?.taxed?.totalGross?.centAmount
          : cartData?.sum?.centAmount ?? 0,
        currencyCode,
        fractionDigits,
      },
    };
  }, [data.data, currency]);

  const trackAddItem = async (skus: string[], quantity = 1, newCart: Cart) => {
    var categoriesResults = await extensions.product.queryCategories({ limit: 99 });
    const categories = (categoriesResults.isError ? [] : (categoriesResults.data?.items as Category[])) ?? [];
    const lineItems = newCart.lineItems.filter((lineItem) => skus.includes(lineItem.variant?.sku as string));
    const itemsWithCategories = await Promise.all(
      lineItems.map(async (lineItem, i) => {
        const product = await getProductByKey(lineItem.productKey ?? '');
        return {
          item_id: lineItem.productKey,
          item_name: lineItem?.name,
          item_variant: lineItem?.variant?.sku || lineItem.productKey,
          index: i,
          price: (lineItem?.price?.centAmount || 0) / 100,
          quantity,
          item_brand: BRANDS.NIKON,
          item_category: product.categories
            ?.map((category) => categories?.find((c) => c.categoryId == category.categoryId)?.name)
            .join(', '),
          item_category4: lineItem?.variant?.attributes?.isRefurbished ? 'Refurbished' : 'New',
          item_category5: lineItem?.variant?.attributes?.title || null,
        };
      }),
    );

    sendGTMEvent({
      event: 'add_to_cart',
      hit_time_stamp: Date.now(),
      userId: account?.accountId,
      userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      ecommerce: {
        currency: currency,
        value: lineItems.reduce((acc, curr) => acc + (curr.price?.centAmount || 0) * quantity, 0) / 100,
        items: itemsWithCategories,
      },
    });
  };

  const addItem = useCallback(async (variant: Variant, quantity: number, tfa30?: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      variant: {
        sku: variant.sku,
        count: quantity,
        tfa30,
      },
    };

    const res: any = await extensions.cart.addItem(payload);

    if (!res.isError && res?.data?.cart) {
      mutate('/action/cart/getCart', res.data.cart);
      await trackAddItem([variant.sku], quantity, res.data.cart);
      return res.data.cart as Cart;
    }

    return {} as Cart;
  }, []);

  const addItemBySku = async (sku: string, quantity: number) => {
    const extensions = sdk.composableCommerce;

    const res: any = await extensions.cart.addItem({
      variant: {
        sku,
        count: quantity,
      },
    });

    if (!res.isError && res?.data?.cart) {
      mutate('/action/cart/getCart', res.data.cart);
      await trackAddItem([sku], quantity, res.data.cart);
      return res.data.cart as Cart;
    }

    return {} as Cart;
  };

  const addItemsBySku = async (skus: string[]) => {
    const res = await sdk.callAction<any>({ actionName: 'cart/addToCart', payload: { skus } });

    if (!res.isError && res?.data?.cart) {
      mutate('/action/cart/getCart', res.data.cart);
      await trackAddItem(skus, 1, res.data.cart);
      return res.data.cart as Cart;
    }

    return {} as Cart;
  };

  const orderCart = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'cart/checkout' });

    return (res.isError ? res : res.data) as Order;
  }, []);

  const getOrder = useCallback(async (orderId: Order['orderId']) => {
    const res = await sdk.callAction({ actionName: 'cart/getOrder', payload: { orderId: orderId } });
    mutate('/action/cart/getCart');

    return (res.isError ? {} : res.data) as Order;
  }, []);

  const getOrderGuest = useCallback(async (order: string, email: string) => {
    const res = await sdk.callAction({
      actionName: 'cart/getOrderGuest',
      payload: { order: order, email: email },
    });
    mutate('/action/cart/getCartGuest');

    return (res.isError ? {} : res.data) as Order;
  }, []);

  const getOrders = useCallback(async (query: PaginatedQuery) => {
    // @ts-ignore
    const res = await sdk.callAction({ actionName: 'cart/getOrders', query: query });

    mutate('/action/cart/getOrders');

    return res.isError ? ({ orders: [] } as Orders) : (res.data as Orders);
  }, []);

  const orderHistory = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.getOrderHistory();

    return res.isError ? ([] as Order[]) : ((<any>res.data).orders as Order[]);
  }, []);

  const getProjectSettings = useCallback(async () => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.project.getSettings();

    return res.isError ? {} : res.data;
  }, []);

  const removeItem = useCallback(async (lineItemId: string) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      lineItem: { id: lineItemId },
    };

    const res: any = await extensions.cart.removeItem(payload);

    if (!res.isError && res?.data?.cart) {
      mutate('/action/cart/getCart', res.data.cart);
      return res.data.cart as Cart;
    }

    return (res.isError ? {} : res.data) as void;
  }, []);

  const updateItem = useCallback(
    async (lineItemId: string, newQuantity: number, tfa30?: string, options?: { warrantyPlanSku?: string }) => {
      const extensions = sdk.composableCommerce;

      const payload = {
        lineItem: {
          id: lineItemId,
          count: newQuantity,
          tfa30,
          warrantyPlanSku: options?.warrantyPlanSku,
        },
      };

      const res: any = await extensions.cart.updateItem(payload);

      if (!res.isError && res?.data?.cart) {
        mutate('/action/cart/getCart', res.data.cart);
        return res.data.cart as Cart;
      }

      return {} as Cart;
    },
    [],
  );

  const updateCart = useCallback(async (payload: CartDetails): Promise<Cart> => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.updateCart(payload);

    mutate('/action/cart/getCart', res);

    return (res.isError ? {} : res.data) as Cart;
  }, []);

  const setShippingMethod = useCallback(async (shippingMethodId: string, signatureRequired: boolean) => {
    const extensions = sdk.composableCommerce;

    const payload = {
      shippingMethod: {
        id: shippingMethodId,
      },
      signatureRequired: signatureRequired,
    };

    const res = await extensions.cart.setShippingMethod(payload);

    mutate('/action/cart/getCart', res);
    return (res.isError ? {} : res.data) as Cart;
  }, []);

  const redeemDiscountCode = useCallback(async (code: string) => {
    const res: any = await sdk.callAction({ actionName: 'cart/redeemDiscount', payload: { code: code.toUpperCase() } });

    if (!res.isError && res?.data?.cart) {
      mutate('/action/cart/getCart', res.data.cart);
      return (res.isError ? {} : res.data) as Cart;
    } else {
      // @ts-ignore
      throw new Error(res?.data || 'This promotion is not valid');
    }
  }, []);

  const removeDiscountCode = useCallback(async (discount: Discount) => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.cart.removeDiscountCode({ discountId: discount.discountId as string });

    mutate('/action/cart/getCart', res);
  }, []);

  const resetCart = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'cart/resetCart' });
    mutate('/action/cart/getCart');

    return (res.isError ? {} : res.data) as void;
  }, []);

  const validateInventory = useCallback(async (cartId) => {
    const res: any = await sdk.callAction({ actionName: 'cart/validateCartInventory', payload: { cartId: cartId } });
    if (!res.isError && res?.data?.cart && Boolean(res?.data?.message?.detail?.skus.length)) {
      mutate('/action/cart/getCart', res.data.cart);
    }
    return (res.isError ? {} : res.data) as void;
  }, []);

  const validateDiscountCodes = useCallback(async (cartId) => {
    const res: any = await sdk.callAction({
      actionName: 'cart/validateCartDiscountCodes',
      payload: { cartId: cartId },
    });
    return (res.isError ? {} : res.data) as void;
  }, []);

  const addPayment = useCallback(async (payment?: Partial<PaymentExtended>): Promise<Cart> => {
    const res: any = await sdk.callAction({
      actionName: 'cart/addPayment',
      payload: { payment },
    });

    mutate('/action/cart/getCart', res);

    return (res.isError ? {} : res.data) as Cart;
  }, []);

  const updatePayment = useCallback(async (payment?: Partial<PaymentExtended>): Promise<PaymentExtended> => {
    const res: any = await sdk.callAction({
      actionName: 'cart/updatePayment',
      payload: { payment },
    });
    mutate('/action/cart/getCart');

    return (res.isError ? {} : res.data) as PaymentExtended;
  }, []);

  const removePayment = useCallback(async (id: string): Promise<Cart> => {
    const res: any = await sdk.callAction({
      actionName: 'cart/removePayment',
      payload: {
        paymentId: id,
      },
    });
    mutate('/action/cart/getCart');
    return (res.isError ? {} : res.data) as Cart;
  }, []);

  const getBundleAndSaveItemForCart = useCallback(async () => {
    const res: any = await sdk.callAction({ actionName: 'cart/getBundleAndSaveItemForCart' });
    return (res.isError ? {} : res.data) as void;
  }, []);

  const getClosenessQualifiers = useCallback(async () => {
    const res: any = await sdk.callAction({ actionName: 'cart/getClosenessQualifiers' });
    return (res.isError ? {} : res.data) as void;
  }, []);

  const fetchSelectedShippingMethodById = useCallback(async (shippingMethodId: string) => {
    const res: any = await sdk.callAction({
      actionName: 'cart/fetchSelectedShippingMethodById',
      payload: { shippingMethodId },
    });
    return (res.isError ? {} : res.data) as ShippingMethod;
  }, []);

  const fetchSelectedCurrentShippingMethodById = useCallback(async (shippingMethodId: string) => {
    const res: any = await sdk.callAction({
      actionName: 'cart/fetchSelectedCurrentShippingMethodById',
      payload: { shippingMethodId },
    });
    return (res.isError ? {} : res.data) as ShippingMethod;
  }, []);

  const getCartWarrantyPlans = useCallback(async (sku: string) => {
    const response = await sdk.callAction({
      actionName: 'cart/getWarrantyPlans',
      query: {
        sku: sku,
        warrantyProductTypeKey: ENikonProductTypeKeys.WARRANTY_PLANS,
      },
    });

    return (response.isError ? [] : response.data) as TNikonProduct[];
  }, []);

  console.groupEnd();
  return {
    ...data,
    totalItems,
    isEmpty,
    isShippingAccurate,
    hasOutOfStockItems,
    cartTotalThreshold,
    isApplePayEnabled,
    isGooglePayEnabled,
    isPaypalEnabled,
    transaction,
    addItem,
    addItemBySku,
    addItemsBySku,
    updateCart,
    setShippingMethod,
    removeItem,
    updateItem,
    shippingMethods,
    orderCart,
    getOrder,
    getOrderGuest,
    getOrders,
    orderHistory,
    getProjectSettings,
    redeemDiscountCode,
    removeDiscountCode,
    resetCart,
    validateInventory,
    validateDiscountCodes,
    addPayment,
    updatePayment,
    removePayment,
    getBundleAndSaveItemForCart,
    getClosenessQualifiers,
    fetchSelectedShippingMethodById,
    fetchSelectedCurrentShippingMethodById,
    getCartWarrantyPlans,
  };
};

export default useCart;
